"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ClientPersonalizedLayout = void 0;
var react_1 = __importStar(require("react"));
var reactstrap_1 = require("reactstrap");
var react_router_dom_1 = require("react-router-dom");
var CircleLoadingIndicator_1 = require("./generic-building-blocks/CircleLoadingIndicator");
var CenterScreenContainer_1 = require("./generic-building-blocks/CenterScreenContainer");
var DataProvider_1 = require("../utils/DataProvider");
var bs_1 = require("react-icons/bs");
var ai_1 = require("react-icons/ai");
require("../sass/ClientPersonalizedLayout.scss");
var AuthService_1 = require("./api-authorization/AuthService");
var fa_1 = require("react-icons/fa");
var io5_1 = require("react-icons/io5");
var gr_1 = require("react-icons/gr");
var fa_2 = require("react-icons/fa");
var md_1 = require("react-icons/md");
var ri_1 = require("react-icons/ri");
var io_1 = require("react-icons/io");
var NotificationsContext_1 = require("../utils/NotificationsContext");
var react_toastify_1 = require("react-toastify");
require("react-toastify/dist/ReactToastify.css");
var react_toastify_2 = require("react-toastify");
var ClientPersonalizedLayout = function (props) {
    var _a = (0, react_1.useState)(false), clientConfigLoaded = _a[0], setClientConfigLoaded = _a[1];
    var _b = (0, react_1.useState)(localStorage.getItem("sideMenuCollapsed") !== "true"), sideMenuOpen = _b[0], setSideMenuOpen = _b[1];
    (0, react_1.useEffect)(function () {
        react_toastify_1.toast.configure();
        (0, DataProvider_1.get_ajax)('configurator/client_configuration', function (value) {
            if (value) {
                setClientConfigLoaded(true);
                var payload = JSON.parse(value);
                window.sessionStorage.setItem("nume_prenume", payload.numePrenume);
                try {
                    var theme_colors = JSON.parse(JSON.parse(payload.companyConfig.JSONClientConfig).theme_json).colors;
                    theme_colors.forEach(function (color) {
                        document.documentElement.style.setProperty(color.name, color.color);
                    });
                }
                catch (e) {
                    console.log("Could not load personalized layout");
                }
            }
        });
    }, []);
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        clientConfigLoaded && (react_1["default"].createElement(react_1["default"].Fragment, null,
            react_1["default"].createElement(SideMenu, { sideMenuOpen: sideMenuOpen, toggleSideMenu: function () {
                    if (!sideMenuOpen)
                        localStorage.setItem("sideMenuCollapsed", "false");
                    else
                        localStorage.setItem("sideMenuCollapsed", "true");
                    setSideMenuOpen(!sideMenuOpen);
                }, routes: props.routes, roleChanged: props.roleChanged }),
            react_1["default"].createElement("div", { className: !sideMenuOpen ? "container_side_nav_expanded" : "container_side_nav_collapsed" },
                react_1["default"].createElement(NotificationsContext_1.NotificationsProvider, null,
                    react_1["default"].createElement(react_toastify_2.ToastContainer, { autoClose: 8000 }),
                    react_1["default"].createElement(TopMenu, null),
                    react_1["default"].createElement("div", { className: "app_parent" },
                        react_1["default"].createElement(reactstrap_1.Container, { fluid: true }, props.children)))))),
        !clientConfigLoaded && (react_1["default"].createElement(CenterScreenContainer_1.CenterScreenContainer, null,
            react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null)))));
};
exports.ClientPersonalizedLayout = ClientPersonalizedLayout;
var SideMenu = function (_a) {
    var sideMenuOpen = _a.sideMenuOpen, toggleSideMenu = _a.toggleSideMenu, routes = _a.routes, roleChanged = _a.roleChanged;
    var _b = (0, react_1.useState)(false), isPickRoleDialogOpen = _b[0], setIsPickRoleDialogOpen = _b[1];
    return (react_1["default"].createElement("div", { className: !sideMenuOpen ? "sidenav shadow sidenav_expanded" : "sidenav shadow" },
        react_1["default"].createElement("a", { className: sideMenuOpen ? "side_menu_icon side_menu_burger_rotated" : "side_menu_icon", onClick: toggleSideMenu },
            react_1["default"].createElement(bs_1.BsList, { size: 28 })),
        react_1["default"].createElement("div", null,
            react_1["default"].createElement(react_router_dom_1.NavLink, { exact: true, to: '/', className: "side_menu_icon", activeClassName: "active" },
                react_1["default"].createElement(bs_1.BsGridFill, { size: 28 }),
                react_1["default"].createElement("label", null, "Acasa")),
            routes.map(function (route, index) {
                return react_1["default"].createElement(react_router_dom_1.NavLink, { key: index, to: route.route, className: "side_menu_icon", activeClassName: "active" },
                    route.icon,
                    react_1["default"].createElement("label", null, route.name));
            })),
        react_1["default"].createElement("div", null,
            react_1["default"].createElement("div", { className: "side_menu_icon", style: { cursor: 'pointer' }, onClick: function () { setIsPickRoleDialogOpen(true); } },
                react_1["default"].createElement(ai_1.AiOutlineLogout, { size: 28 }))),
        react_1["default"].createElement(SwitchRoleDialog, { isOpen: isPickRoleDialogOpen, toggle: function () { setIsPickRoleDialogOpen(false); }, roleChanged: roleChanged })));
};
var TopMenu = function () {
    var _a = (0, NotificationsContext_1.useGlobalSync)(), getOrCreateConnection = _a.getOrCreateConnection, notificationsCount = _a.notificationsCount, setNotificationsCount = _a.setNotificationsCount;
    (0, react_1.useEffect)(function () {
        getOrCreateConnection("/sync_hubs/notifications", function (connection) {
            connection.start().then(function (result) {
                connection.on('receive_toast_notification', function (data, type) {
                    (0, NotificationsContext_1.displayToastNotification)(data, type);
                });
                connection.on('receive_persistent_notification', function () {
                    setNotificationsCount(notificationsCount + 1);
                    connection.invoke('FetchNotifications');
                });
                connection.on('receive_persistent_notifications', function (notfications) {
                    setNotifications(notfications);
                    setNotificationsCount(1);
                });
                connection.invoke('FetchNotifications');
            });
        });
    }, []);
    var _b = (0, react_1.useState)([]), notifications = _b[0], setNotifications = _b[1];
    var _c = (0, react_1.useState)(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    return (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("div", { className: "top_menu noselect" },
            react_1["default"].createElement("div", { className: 'right_box' },
                notificationsCount !== 0 && (react_1["default"].createElement(io_1.IoIosNotifications, { size: 28, className: "notifications_icons", onClick: function () { setIsModalOpen(true); } })),
                react_1["default"].createElement("img", { src: "/avatar.png", className: "avatar" }))),
        react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isModalOpen, toggle: function () { setIsModalOpen(false); }, size: 'lg' },
            react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Notificari"),
            react_1["default"].createElement(reactstrap_1.ModalBody, null,
                react_1["default"].createElement("ul", { className: "list-group" }, notifications.map(function (notification) {
                    return react_1["default"].createElement("li", { className: "list-group-item", key: notification.id },
                        notification.notificationContent,
                        react_1["default"].createElement(md_1.MdOutlineMarkChatRead, { className: "float-right", onClick: function () {
                                getOrCreateConnection("/sync_hubs/notifications", function (connection) { }).invoke("MarkNotificationAsRead", notification.id);
                                if (notifications.length === 1)
                                    setIsModalOpen(false);
                            } }));
                }))))));
};
var SwitchRoleDialog = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle, roleChanged = _a.roleChanged;
    var _b = (0, react_1.useState)(undefined), allowedRoles = _b[0], setAllowedRoles = _b[1];
    (0, react_1.useEffect)(function () {
        if (isOpen === false)
            return;
        (0, AuthService_1.GetRoles)(function (roles) {
            setAllowedRoles(roles.filter(function (e) {
                return (e.name === "Medic SSM" || e.name === "Dosar medical" || e.name === "HrManager" || true //Vedem ce roluri afisam si ce roluri nu, momentan nu stiu
                );
            }));
        });
    }, [isOpen]);
    return (react_1["default"].createElement(reactstrap_1.Modal, { isOpen: isOpen, toggle: toggle, size: 'lg' },
        react_1["default"].createElement(reactstrap_1.ModalHeader, null, "Alege un alt rol"),
        react_1["default"].createElement(reactstrap_1.ModalBody, null,
            allowedRoles === undefined && (react_1["default"].createElement(CircleLoadingIndicator_1.LoadingCircleComponent, null)),
            allowedRoles !== undefined && (react_1["default"].createElement("div", { className: 'row' }, allowedRoles.map(function (role, i) {
                return react_1["default"].createElement("div", { className: 'col-md-4', key: i },
                    react_1["default"].createElement("div", { className: 'role_option' },
                        react_1["default"].createElement("div", { className: 'role_option_content', onClick: function () {
                                toggle();
                                roleChanged(role.name);
                            } },
                            role.name === "Administrator" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(gr_1.GrConfigure, { size: 28 }),
                                react_1["default"].createElement("p", null, "Administrator"))),
                            role.name === "SSM Expert" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(gr_1.GrUserWorker, { size: 28 }),
                                react_1["default"].createElement("p", null, "Expert SSM"))),
                            role.name === "Dosar medical" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(fa_1.FaNotesMedical, { size: 28 }),
                                react_1["default"].createElement("p", null, "Dosar medical"))),
                            role.name === "Medic SSM" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(io5_1.IoMedical, { size: 28 }),
                                react_1["default"].createElement("p", null, "Medic SSM"))),
                            role.name === "HrManager" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(fa_2.FaSignature, { size: 28 }),
                                react_1["default"].createElement("p", null, "Director"))),
                            role.name === "Angajat" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(ri_1.RiUserFill, { size: 28 }),
                                react_1["default"].createElement("p", null, "Angajat"))),
                            role.name === "Angajat manager" && (react_1["default"].createElement(react_1["default"].Fragment, null,
                                react_1["default"].createElement(md_1.MdManageAccounts, { size: 28 }),
                                react_1["default"].createElement("p", null, "Angajat manager"))))));
            })))),
        react_1["default"].createElement(reactstrap_1.ModalFooter, null,
            react_1["default"].createElement(reactstrap_1.Button, { color: "secondary", className: "no-outline", onClick: toggle }, "Anuleaza"))));
};
