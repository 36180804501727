import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { SearchBox } from 'shared/dist/components/generic-building-blocks/SearchBox';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import { RoundRedirectButton } from 'shared/dist/components/generic-building-blocks/RoundButton';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { get_ajax } from 'shared/dist/utils/DataProvider';
import {FaUser}from 'react-icons/fa';

export const Workers = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);

    const [searchBoxValue,setSearchBoxValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 12;

    useEffect(()=>{     
        AOS.init();            
    }, []);

    useEffect(()=>{
        setSearchBoxValue('');
        loadData('', currentPage);
    }, [currentPage]);

    const loadData = (searchBy, page) => {
        get_ajax("EmployeeManager/MyTeam/GetEmployees?searchBy=" + searchBy + "&page=" + page, (response) =>{
            setEmployees(response);
            setIsLoading(false);
        });
    }

    return (
        <>
            <br/>
            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                <h4>Echipa mea</h4>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <SearchBox 
                            value={searchBoxValue} 
                            placeholder="Cauta" 
                            onChange={(e) =>{ loadData(e.target.value); setSearchBoxValue(e.target.value); }}
                        />
                    </div>
                </div>
            </LayoutCard>
            {isLoading && (
                <>
                    <br/>
                    <LoadingCircleComponent/>
                </>
            )}
            {!isLoading && (
                <div className='row'>
                    <div className='col-md-8'>
                        <div className="row">
                            {employees.map((e, i) =>
                                <div className='col-md-4' key={i}>
                                    <LayoutCard                                        
                                        className={e.isActive === false ? "layout_card1 shadow-sm disabled_grayscale" : "layout_card1 shadow-sm"}
                                        key={i} 
                                    >
                                        <div style={{textAlign: 'center'}}>                                                    
                                            <FaUser size={64} className="avatar_container"/>
                                                <h6>{`${e.nume} ${e.prenume}`}</h6>
                                                <label>
                                                    {e.locMunca}
                                                    {/* {e.isTrueEmployee == false && (
                                                        <CgDanger style={{marginLeft: '5px'}}/>
                                                    )} */}
                                                </label>
                                                <br/>                                          
                                        </div>
                                    </LayoutCard>
                                </div>
                            )}
                        </div>
                        {employees.length == 0 && (
                            <><br/><p style={{textAlign: 'center'}}>Nu exista lucratori.</p></>
                        )}
                        <ul className="pagination">
                            <li className="page-item">
                                <button 
                                    disabled={currentPage <= 0} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage-1)}}
                                    style={{borderRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                >
                                    Inapoi
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    className="page-link btn no-outline"
                                    style={{cursor: "auto", borderRadius: 0}}
                                >
                                    {currentPage+1}
                                </button>
                            </li>
                            <li className="page-item">
                                <button 
                                    disabled={employees.length < pageSize} 
                                    className="page-link btn no-outline" 
                                    onClick={()=>{setCurrentPage(currentPage+1)}}
                                    style={{borderRadius: '10px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                >
                                    Inainte
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}