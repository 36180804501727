import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Home } from './pages/Home';

import './App.scss';
import { getAccessToken, GetRoles } from 'shared/dist/components/api-authorization/AuthService';
import {ClientPersonalizedLayout} from 'shared/dist/components/ClientPersonalizedLayout';
import {PaddingBox} from 'shared/dist/components/generic-building-blocks/PaddingBox';
import { BsPeopleFill, BsTable } from "react-icons/bs";
import {GoSettings} from 'react-icons/go';
import { LoadingCircleComponent } from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import { CenterScreenContainer } from 'shared/dist/components/generic-building-blocks/CenterScreenContainer';
import {FaFileMedicalAlt} from 'react-icons/fa';
import {FaCommentMedical} from 'react-icons/fa';
import { BsListCheck } from 'react-icons/bs';
import { RiArchiveFill } from 'react-icons/ri';
import {MdIntegrationInstructions, MdModelTraining} from 'react-icons/md';
import { Workers } from './pages/employee_manager/workers/Workers';

export default class App extends Component {
    static displayName = App.name;    

    constructor(props)
    {
        super(props);        
        this.state = {
          current_role: undefined,     
          isLoadingNewUI: false,     
        };

        getAccessToken();
        let allowed_roles = [];
        GetRoles((roles)=> {                    
          allowed_roles = roles.filter((e)=>{
            return (
              e.name === "Medic Personal" || e.name === "Dosar medical" || e.name === "HrManager"
            );
          });                    
          let c_role = {name: window.localStorage.getItem("__current_role__")};
          if(c_role === '' || c_role === null || c_role === undefined)
            c_role = allowed_roles[0];
          this.setState({allowed_roles: allowed_roles, current_role: c_role});
        });  
    }

    returnAllowedRoutes = () => {                  
        const current_role = this.state.current_role.name;   
        if(current_role === undefined) return ([]);
        window.localStorage.setItem("__current_role__", current_role);
        if (current_role == 'Angajat manager'){
          return(
            [
              {
                "route": "/employee_manager/workers",
                "name": "Echipa",
                "icon": <BsPeopleFill size={28}/>,                    
              },
              // {
              //   "route": "/employee_manager/reports",
              //   "name": "Raport",
              //   "icon": <BsTable size={28}/>,
              // },
              // {
              //   "route": "/employee_manager/configure_machete",
              //   "name": "Machete",
              //   "icon": <GoSettings size={28}/>,                    
              // }
            ]
          );
        }
        else if (current_role == 'Angajat'){
          return ([]);
        }
        else return ([]);
    }

    render() {  
        if(this.state.current_role === undefined || this.state.isLoadingNewUI) {
          return(
            <CenterScreenContainer>
              <LoadingCircleComponent/>
            </CenterScreenContainer>
          );
        }
        return (
            <ClientPersonalizedLayout
              app={'Personal'}                     
              routes={this.returnAllowedRoutes()}   
              roleChanged={(new_role) => {
                this.setState({current_role: {name: new_role, isLoadingNewUI: true }});
                window.localStorage.setItem("__current_role__", new_role);
                window.location.href='/';
              }}          
            >
              <PaddingBox
                top='0'
                right='0'
                bottom='0'
                left='15px'
              >                
                <Route exact path='/' component={Home} />
                <Route path='/employee_manager/workers' component={Workers} />
              </PaddingBox>              
            </ClientPersonalizedLayout>
        );
  }
}