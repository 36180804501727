import React, { useEffect, useState } from 'react';
import { LayoutCard } from 'shared/dist/components/generic-building-blocks/LayoutCard';
import AOS from 'aos';
import "aos/dist/aos.css";
import {get, get_ajax, post} from 'shared/dist/utils/DataProvider';
import {LoadingCircleComponent} from 'shared/dist/components/generic-building-blocks/CircleLoadingIndicator';
import {IoWarningOutline, IoCheckmark, IoHourglassOutline} from 'react-icons/io5';
import {Link, Redirect} from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

const data_fisa_aptitudini = {
    labels: ['Urmeaza sa expire', 'Expirate', 'Valabile'],
    datasets: [{
        label: '# of Votes',
        data: [12, 19, 3],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1
    }]
};
const data_instruiri = {
    labels: ['Valabil', 'Programat la instruire', 'Expirat'],
    datasets: [{
        label: '# of Votes',
        data: [32, 1, 9],
        backgroundColor: [
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
    }]
};

export const Home = () => {
    useEffect(()=>{
        AOS.init();
    }, []);

    const role = window.localStorage.getItem('__current_role__');
    if(role === "Angajat manager")
        return (<ManagerEmployeeHomePage/>);
    return (
        <>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Bun venit!</h5>
                            <label>Va uram o zi frumoasa!</label>
                            
                            <br/><small>Acest modul al aplicatiei este in lucru.</small>
                        </div>
                    </LayoutCard>
                    <div className='row'>
                        <div className='col-md-6'>
                            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                <div>
                                    <h5>Fisa de aptitudini</h5>
                                    <label>
                                        Valabila pana la data de 15.09.2022 
                                        <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{marginLeft: '5px'}}/>
                                    </label>
                                </div>
                            </LayoutCard>
                        </div>
                        <div className='col-md-6'>
                            <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                                <div>
                                    <h5>Instruiri</h5>
                                    <label>
                                        Nu exista instruiri noi programate
                                        <IoCheckmark size={28} fill='#2ecc71' color='#2ecc71' style={{marginLeft: '5px'}}/>
                                    </label>
                                </div>
                            </LayoutCard>
                        </div>
                    </div>
                </div> 
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Dosar personal</h5>
                                <label>
                                    Fisa post
                                </label>
                            </div>
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Instruiri</h5>
                                <ul>
                                    <li>SSM</li>
                                    <li>SU</li>
                                </ul>
                            </div>
                    </LayoutCard>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Formare profesionala</h5>
                                <label>
                                    Competente
                                </label>
                            </div>
                    </LayoutCard>
                </div>               
            </div>
        </>
    )
}

const ManagerEmployeeHomePage = () => {
    return (
        <>
            <br/>
            <div className="row">
                <div className="col-md-6">
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Bun venit!</h5>
                            <label>Va uram o zi frumoasa!</label>

                            <br/><small>Acest modul al aplicatiei este in lucru.</small>
                        </div>
                    </LayoutCard>                    
                </div>
                <div className='col-md-6'>
                    <LayoutCard className="layout_card_25bottom layout_card_padded shadow-sm">
                        <div>
                            <h5>Echipa mea</h5>
                        </div>             
                        <br/>        
                        <div className='row'>
                            <div className='col-md-6'>
                                <label style={{textAlign: 'center', width: '100%'}}>Fise de aptitudini</label>
                                <Doughnut data={data_fisa_aptitudini}/>
                            </div>
                            <div className='col-md-6'>
                                <label style={{textAlign: 'center', width: '100%'}}>Instruiri</label>
                                <Doughnut data={data_instruiri}/>
                            </div>
                        </div>
                    </LayoutCard>
                </div>
            </div>
        </>
    )
}